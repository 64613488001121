import React from "react";
import Footer from "src/components/Footer_es";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div id="disclosureText">
        </div>
        <div id="page" className="pageProducts">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/es/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="nav-path-selected first has-sub-nav">
                    <a className="es_header" href="/es/nuestros-productos/" target="_self">
                      Nuestros Productos
                    </a>
                    <ul>
                      <li>
                        <a href="/es/nuestros-productos/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      {/* <li>
                        <a href="/es/nuestros-productos/active-protect/">
                          Active &amp; Protect
                        </a>
                      </li> */}
                      <li>
                        <a href="/es/nuestros-productos/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/tan-protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      {/* <li>
                        <a href="/es/nuestros-productos/instantglow/">
                          Instant Glow
                        </a>
                      </li> */}
                      <li>
                        <a href="/es/nuestros-productos/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/mountain/">Mountain</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/after-sun/">
                          After Sun
                        </a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/galeria/">Galeria</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/es/nuestra-historia/" target="_self">
                      Nuestra historia
                    </a>
                  </li>
                  <li>
                    <a className="es__header" href="/es/conozca-el-sol/" target="_self">
                      Conozca el sol
                    </a>
                  </li>
                   {/*<li>
                    <a className="es_header" href="/es/donde-has-estado/" target="_self">
                      Dónde has estado 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      Países
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/our-products/all_products/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/our-products/all_products/">UK</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/galeria/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/todos_os_Produtos/">PT</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/gallery/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/gallery/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="content">
              <div className="gallery-page">
                <h1 className="gallery-page__title">
                  TODOS LOS PRODUCTOS PIZ BUIN<sup>®</sup>
                </h1>
                <div className="clearfix">
                  <div className="gallery-page__filter hidden">
                    <div className="arrow">
                      <br />
                    </div>
                    <form method id="fmFilter">
                      <select
                        name="filter"
                        className="gallery-page__filter__select"
                      >
                        <optgroup>
                          <option value="0">Filter by: All</option>
                          <option value="1,2,6,9,12,13">Default 1</option>
                          <option value="12,18,19">Default 2</option>
                          <option value="1,2,4">Default 3</option>
                          <option value="1">Default 4</option>
                          <option value="13">Default 5</option>
                        </optgroup>
                      </select>
                    </form>
                  </div>
                </div>
                <div className="innerContent clearfix gallery-page__list">
                  <a
                    href="/es/nuestros-productos/hydro-infusion/#sun-gel-cream-face"
                    className="gallery-page__list__item"
                    data-pid="1"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-hydro-infusion-cream-50spf-50ml-gal.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Hydro Infusion
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          SUN GEL CREAM FACE
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/es/nuestros-productos/hydro-infusion/#sun-gel-cream"
                    className="gallery-page__list__item"
                    data-pid="1"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-hydro-infusion-sun-gel-cream-50spf-150ml-gal.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Hydro Infusion
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          SUN GEL CREAM
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/es/nuestros-productos/active-protect/#actect"
                    className="gallery-page__list__item"
                    data-pid="1"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-active-protect-sun-lotion-100ml-gal.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Active &amp; Protect
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          SUN LOTION
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/es/nuestros-productos/moisturising/#loci-n-solar-hidratante"
                    className="gallery-page__list__item"
                    data-pid="1"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-moisturising-sun-lotion-50spf-200ml-gal.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Moisturising
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          LOCIÓN SOLAR HIDRATANTE
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/es/nuestros-productos/moisturising/#stick-labial"
                    className="gallery-page__list__item"
                    data-pid="1"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-moisturising-sun-lipstick-aloe-vera-gal.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Moisturising
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          STICK LABIAL
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/es/nuestros-productos/moisturising/#spray-solar-hidratante"
                    className="gallery-page__list__item"
                    data-pid="2"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/8bf310057998eb9035da232074f98217_f33.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Moisturising
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          SPRAY SOLAR HIDRATANTE
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/es/nuestros-productos/tan-protect/#aceite-en-spray-acelerador-del-bronceado"
                    className="gallery-page__list__item"
                    data-pid="3"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/597e55fa85eadc497dda9761c9c29e48_f305.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Tan &amp; Protect
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          ACEITE EN SPRAY ACELERADOR DEL BRONCEADO
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/es/nuestros-productos/tan-protect/#loci-n-solar-intensificadora-del-bronceado"
                    className="gallery-page__list__item"
                    data-pid="3"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/cdb96eb7dd5017e10ae2166b22fc0b37_f28.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Tan &amp; Protect
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          LOCIÓN SOLAR INTENSIFICADORA DEL BRONCEADO
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/es/nuestros-productos/tan-protect/#spray-solar-intensificador-del-bronceado"
                    className="gallery-page__list__item"
                    data-pid="3"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/33e7e9d0e3e36224abe8382693902bac_f547.png" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Tan &amp; Protect
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          SPRAY SOLAR INTENSIFICADOR DEL BRONCEADO
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/es/nuestros-productos/instantglow/#spray-solar-iluminador-"
                    className="gallery-page__list__item"
                    data-pid="9"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-instant-glow-skin-illuminating-sun-spray-30spf-150ml-gal.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Instant Glow
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          SPRAY SOLAR ILUMINADOR{" "}
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/es/nuestros-productos/instantglow/#locion-solar-iluminadora-"
                    className="gallery-page__list__item"
                    data-pid="9"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-instant-glow-skin-illuminating-50spf-150ml-gal.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Instant Glow
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          LOCIÓN SOLAR ILUMINADORA{" "}
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/es/nuestros-productos/allergy/#loci-n-piel-sensible"
                    className="gallery-page__list__item"
                    data-pid="4"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-allergy-sun-sensitive-skin-lotion-50spf-200ml-gal.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Allergy
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          LOCIÓN PIEL SENSIBLE
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/es/nuestros-productos/allergy/#spray-piel-sensible"
                    className="gallery-page__list__item"
                    data-pid="4"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-allergy-sun-sensitive-skin-spray-50spf-200ml-gal.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Allergy
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          SPRAY PIEL SENSIBLE
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/es/nuestros-productos/allergy/#crema-rostro-piel-sensible"
                    className="gallery-page__list__item"
                    data-pid="4"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-allergy-face-cream-50spf-50ml-gal.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Allergy
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          CREMA ROSTRO PIEL SENSIBLE
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/es/nuestros-productos/mountain/"
                    className="gallery-page__list__item"
                    data-pid="5"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/mountain-lipstick-new-gal.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Mountain
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          CREMA SOLAR + STICK LABIAL
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/es/nuestros-productos/after-sun/#after-sun-loci-n-hidratante-calmante-y-refrescante"
                    className="gallery-page__list__item"
                    data-pid="7"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-after-sun-soothing-cooling-moisturising-lotion-200ml-gal.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          After Sun
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          AFTER SUN LOCIÓN HIDRATANTE, CALMANTE Y REFRESCANTE
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/es/nuestros-productos/after-sun/#loci-n-hidratante-intensificadora-del-bronceado"
                    className="gallery-page__list__item"
                    data-pid="7"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-after-sun-tan-intensifying-moisturising-lotion-200ml-gal.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          After Sun
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          LOCIÓN HIDRATANTE INTENSIFICADORA DEL BRONCEADO
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/es/nuestros-productos/after-sun/#instant-mist-spray"
                    className="gallery-page__list__item"
                    data-pid="7"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2019-after-sun-instant-mist-spray-200ml-gal.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          After Sun
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          INSTANT RELIEF MIST SPRAY
                        </h3>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </section>
            <section id="social">
              <a
                href="https://www.facebook.com/PizBuinEspana"
                target="_blank"
                className="fb"
              >
                &nbsp;
              </a>
              <a
                href="https://instagram.com/pizbuinespana"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
              <a
                href="https://www.youtube.com/channel/UC4UKe2bJ7_abRLvHmWKSzzg"
                target="_blank"
                className="yt"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        
        <script
          dangerouslySetInnerHTML={{
            __html:
              '\n$(document).ready(function(){\nvar i = $(".heroImage > img").attr(\'src\');\n$("#bgImage").css("background-image","url("+i+")");\n$(".heroImage").empty();\n})\n'
          }}
        />
      </div>
    );
  }
}

export default Page;
